import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [{
		path: '/',
		name: 'index',
		component: () => import( /* webpackChunkName: "My" */ '../views/index.vue'),
		meta: {
			title: '2022浙江少年诗词大会'
		}
	},
	{
		path: '/BindInfo',
		name: 'BindInfo',
		component: () => import( /* webpackChunkName: "BindInfo" */ '../views/apply/BindInfo.vue'),
		meta: {
			title: '绑定学生信息'
		}
	},
	{
		path: '/AnswerCard',
		name: 'AnswerCard',
		component: () => import( /* webpackChunkName: "BindInfo" */ '../views/apply/AnswerCard.vue'),
		meta: {
			title: '倒计时'
		}
	},
	{
		path: '/ExamRead',
		name: 'ExamRead',
		component: () => import( /* webpackChunkName: "BindInfo" */ '../views/apply/ExamRead.vue'),
		meta: {
			title: '答题须知'
		}
	},
	{
		path: '/score',
		name: 'score',
		component: () => import( /* webpackChunkName: "BindInfo" */ '../views/apply/score.vue'),
		meta: {
			title: '我的成绩'
		}
	},
	{
		path: '/completed',
		name: 'completed',
		component: () => import( /* webpackChunkName: "BindInfo" */ '../views/apply/completed.vue'),
		meta: {
			title: '测评完成'
		}
	},
	{
		path: '/testScore',
		name: 'testScore',
		component: () => import( /* webpackChunkName: "BindInfo" */ '../views/apply/testScore.vue'),
		meta: {
			title: '测试页面'
		}
	}
]

const router = new VueRouter({
	mode: 'hash',
	base: process.env.BASE_URL,
	routes
})

export default router
