import Vue from 'vue'
import App from './App.vue'
import router from './router'
import Vconsole from 'vconsole'
import wx from 'weixin-js-sdk'
import "flex.css"

// import '@/utils/lib-flexible.js'
if(process.env.NODE_ENV === 'development') {
  new Vconsole()
}
new Vconsole()

import { Button, Dialog, Radio, Icon, Toast, Overlay, Form, Field, Picker, Popup, RadioGroup, Area, Loading, Notify, Skeleton} from 'vant';

Vue.config.productionTip = false
Vue.prototype.$wx = wx;

Vue.use(Toast);
Vue.use(Button);
Vue.use(Dialog);
Vue.use(Radio);
Vue.use(Icon);
Vue.use(Overlay);
Vue.use(Form);
Vue.use(Field);
Vue.use(Picker);
Vue.use(Popup);
Vue.use(Radio);
Vue.use(RadioGroup);
Vue.use(Area);
Vue.use(Loading);
Vue.use(Notify);
Vue.use(Skeleton);

router.beforeEach((to, from, next) => {
  /* 路由发生变化修改页面title */
  if (to.meta.title) {
    document.title = to.meta.title
  }
  next()
})


new Vue({
  router,
  render: h => h(App)
}).$mount('#app')
